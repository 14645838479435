export default {
  child: 'barn',
  employee: 'medarbejder',
  guardian: 'forælder',
  children: 'Børn',
  employees: 'Medarbejdere',
  guardians: 'Forældre',
  participants: 'Deltagere',
  contact_information: 'Kontaktoplysninger',
  personal_data: 'Stamkort',
  app_history: 'App historik',
  notifications: 'Notifikationer',
  privacy_policy: 'Privatlivspolitik',
  log_out: 'Log ud',
  log_out_warning: 'Er du sikker på, at du vil logge ud?',
  switch_to_profile: 'Skift bruger til {profile}',
  switch_profile_warning: 'Er du sikker på, at du vil skifte profil?',
  response_history: 'Svarhistorik',
  master_data_questions: 'Spørgsmål om stamdata og tilladelser',
  yes_no_response: 'Ja/nej svar',
  response: 'Svar',
  filter_by_children: 'Filtrer på barn',
  filter_by_institution: 'Filtrer på institution',
  communication_restrictions: 'Kommunikationsrestriktioner',
  blocked_communication: 'Blokeret kommunikation',
  change_profile_picture: 'Skift profilbillede',
  select_primary_institution: 'Vælg din primære institution som {role}',
  alias: 'alias',
};
