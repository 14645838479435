export enum permissionEnum {
  ADMIN_MODULE = 0, // Administration Module
  SEARCH_ACCESS_PROFILES = 1, // Search and access profiles
  SEARCH_ACCESS_GROUPS = 2, // Search and access groups
  HANDLE_UNI_AND_INSTITUTIONAL_GROUPS = 3, // Create and edit institutional groups
  HANDLE_MUNICIPAL_GROUPS = 4, // Create and edit Interinstitutional groups
  HANDLE_USER_ROLES = 5, // Edit user roles
  HANDLE_REPORTS = 6, // Handle reports of posts
  DELETE_POSTS_MEDIA_COMMENTS = 7, // Delete posts, media and comments
  HANDLE_RESOURCES_INSTITUTION = 9, // Handle resourceson institution
  HANDLE_ADDITIONAL_MASTER_DATA = 10, // Handle Additional master data and permissions
  HANDLE_PHYSICAL_LOCATION = 11, // Handle physical location
  HANDLE_COMMON_FILES = 12, // Handle important files
  HANDLE_SHARED_INBOX = 13, // Handle shared inbox
  HANDLE_USER_DATA = 14, // See, search, export and delete data related to user
  HANDLE_COMMUNICATION_CHANNELS_MUNICIPALITY = 16, // Handle communication channels on municipality
  HANDLE_ADMINISTRATIVE_AUTHORITY = 17, // Handle Administrative authority
  HANDLE_ADDITIONAL_MASTER_DATA_BRUTTO_LIST = 21, // Handle Additional master data and permissions brutto list
  HANDLE_CONSENTS = 22, // Handle consents
  HANDLE_LESSON_IMPORT_TIME = 23, // Handle time for import of lessons
  HANDLE_MAX_FILESIZE = 24, // Handle maximum filesize
  HANDLE_FILEFORMATS = 25, // Handle not allowed file formats
  ACCESS_SECURE_FILESHARING = 26, // Access to secure filesharing
  HANDLE_SECURE_FILES = 27, // Handle secure files
  HANDLE_SECURE_FILES_LIMITED = 28, // Limited handling of secure files
  ACCESS_COMMON_FILES = 29, // Access to important files
  READ_MESSAGE = 31, // Read message
  WRITE_MESSAGE = 32, // Write message
  READ_POST = 33, // Read post
  WRITE_POST = 34, // Write post
  SHARE_MEDIA = 35, // Share media
  SEE_MEDIA = 36, // See media
  HANDLE_GROUP_APPLICATION = 37, // Handle group application
  WRITE_COMMENTS = 38, // Write comments
  SEE_CALENDAR = 39, // See calendar
  HANDLE_EVENTS = 41, // Create and edit event
  HANDLE_PARENTAL_MEETING_SCHOOL = 43, // Create and edit parental meeting
  HANDLE_PERFORMANCE_MEETING = 44, // Create and edit performance meeting
  INVITE_TO_EVENT = 46, // Invite others to events
  ANSWER_EVENT_WITH_EXTENDED_ANSWER = 47, // Answer event with extended answer
  SHARE_SECURE_FILES = 48, // Share secure files and edit shared secure files
  HANDLE_COMMUNICATION_CHANNELS_CENTRAL = 49, // Handle communication channels centrally
  HANDLE_TRANSITION_YEAR = 50, // Handle transition year
  HANDLE_RESOURCES_MUNICIPALITY = 51, // Handle resources on municipality
  SEND_SMS = 52, // Right to send SMS to the users
  WRITE_INFO_PROFILE = 53, // Right to write info on own profile regarding the user
  MESSAGE_START_MULTIPLE_THREADS = 54, // Start individual threads for each recipient
  HANDLE_PARENTAL_MEETING_DAYCARE = 55, // Create and edit parental meeting
  INBOX_SET_PERSONAL_AUTOREPLY = 56, // Set autoreply on personal inbox
  INBOX_FOLDERS = 57, // Access to inbox folder functionality
  MESSAGE_SEE_SUBSCRIBERS_LASTREAD = 58, // See lastread for all subscribers on a thread
  HANDLE_CONSENT_AGE = 59, // Handle consent age
  HANDLE_DASHBOARD = 60, // Handle dashboards
  REPORT_POSTS_MEDIA_COMMENTS = 62, // Report posts, media and comments
  SEE_GUARDIAN_CHILD_CONTACT_INFORMATION = 63, // See contact information for children and guardians
  SEE_EMPLOYEE_CONTACT_INFORMATION = 64, // See contact information for employees
  SEE_GUARDIAN_CHILD_LAST_LOGIN = 65, // See last logon of guardian and child
  SEE_EMPLOYEE_LAST_LOGIN = 66, // See last logon of employee
  HANDLE_CONTACTS = 67, // Handle own external contacts
  VIEW_USERS_ADDITIONAL_DATA = 68, // Access additional master data and permissions answered by users
  HANDLE_SIGNATURE = 69, //
  VIEW_MEDIA_REGARDLESS_OF_CONSENT = 71, // Access to viewing Portrait Media regardless of Consents
  VIEW_CONTACT_INFORMATION_REGARDLESS_OF_CONSENT = 72, // Access to viewing Contact Information regardless of Consents
  HANDLE_MAX_IMAGE_RESOLUTION = 73, // Handle maximum image resolution
  HANDLE_MAX_VIDEO_LENGTH = 74, // Handle maximum video length
  VIEW_USERS_CONSENTS = 75, // Access Consents given by users
  TAG_OTHER_USERS_ON_OTHER_MEDIA = 76, // Tag user besides own children on Media imported by others
  ATTACH_GOOGLE_DRIVE_FILE = 77, // Attach file from Google Drive to Message, Calendar Event and Post
  IMPORT_MEDIA_FROM_GOOGLE_DRIVE = 80, // Import Media from Google Drive
  ATTACH_ONEDRIVE_FILE = 81, // Attach file from OneDrive to Message, Calendar Event and Post
  IMPORT_MEDIA_FROM_ONEDRIVE = 84, // Import Media from OneDrive
  SEE_CONTACT_PARENTS_CONTACT_INFO = 85, // Access list of contact parents contact information
  HANDLE_MEDIA = 87, // Handle media
  IMPERSONATE_USER = 89, // Access to functionatility to impersonate another user
  VIEW_EMPLOYEES_ADDITIONAL_DATA = 90, // Can view additional data for other employees
  HANDLE_SERVICE_MESSAGES = 91, // Can update Service message and disclaimer for service request page in admin module
  PAIRING_INSTITUTION_AND_DEVICE = 92, // Able to access and pair institution and devices
  VIEW_PRESENCE_STATISTICS = 93, // Is allowed to see statistics for presence module
  HANDLE_VACATION_REQUESTS = 94, // Is allowed to send requests for vacation to guardians
  HANDLE_OPTIONS_PRESENCE_DASHBOARD = 95, // Is allowed to update settings for presence dashboard
  USE_GROUPS_AS_DISTRIBUTION_LISTS = 96, // Create messages, events and post for non-member groups
  VIEW_CUSTODY = 98, // View custody of children
  SECURE_DOCUMENTS_ACCESS_ALL = 101, // Permission given to employees who can unlock locked secure document
  CREATE_EVENTS_IN_INSTITUTION_CALENDAR = 102, // Permission given to employees who can create events in the institution calendar
  HANDLE_CENTRAL_ADMINISTRATION_TOOLBOX = 103, // Permission given to central admins in order to see the central admin toolbox
  ASSOCIATE_SECURE_DOCUMENTS_TO_ALL_GROUPS = 104, // Permission to associate secure documents to all groups within an institution
  CREATE_EVENTS_ONLY_IN_INSTITUTION_CALENDAR = 105, // Allows employee to create events to the Institution's Calendar without the event is present in their own calendar.
  VIEW_PERSONAL_REFERENCE_DATA_FOR_ALL_CHILDREN_AND_GUARDIAN = 106, // Permission to view personal referencedata for all children and guardians on institution,
  VIEW_CONTACT_INFORMATION_ALL = 107, // Permission for employees to see contact lists regarding users in groups they are not a member of on institution
  HANDLE_GROUP_TEMPLATES = 108, // Permission for municipal admins to handle group templates in their municipality
  HANDLE_NOTICE_BOARDS = 109, // Permission for institutional admins to handle notice boards in their institution
  HANDLE_ACCESS_CONTACT_INFO = 110, // Permission for municipal admin to handle contact info access
  HANDLE_DATA_POLICY = 111, // Permission for municipal admin to handle contact info access
  HANDLE_SERVICE_REQUEST = 112, // Permission for municpal admins to handle Service request in their municipality. This should automatically be given to municipal administrator and should not show up in the list of assignable permissions
  HANDLE_CALENDAR_FEED_MUNICIPALITY = 113, // Permission for municpal admins to enable or disable calendar feeds in their municipality. This should automatically be given to municipal administrator and should not show up in the list of assignable permissions
  EXPORT_PRESENCE_STATISTICS = 114, // Is allowed to export statistics for presence module
  EXPORT_SECURE_FILES = 115, // Permission for municipal admins to export PDF version of secure documents
  READ_SECURE_FILES = 116, // Permission for guardians to read secure files
  EDIT_PRESENCE_TEMPLATES = 117, // Permission for employees to edit presence templates
  HANDLE_DOCUMENT_TEMPLATES = 118, // Permission to administer document templates
  HANDLE_EVENT_CO_ORGANIZER = 119, // Permission to add co-organizers to events and meetings
  HANDLE_CROSS_INSTITUTIONAL_GROUPS = 120, // Permission to Create and edit cross institutional groups
  REPORT_EMPLOYEE_CONTENT = 121, // Permission to report post content
  HANDLE_USER_LOCK = 122, // Access to manipulate locking/unlocking users
  HANDLE_CHILD_PROFILE_PICTURE = 123, // Permission to change/delete children's profile picture
  DELETE_ACCESSIBLE_ALBUM = 124, // Allow deletion of accessible album
  HANDLE_OTHERS_EVENTS = 125, // Edit and delete other user's events
  HANDLE_MUNICIPAL_WIDGET_PERMISSIONS = 128, // Permission for municipal administrators to create/edit allowed widgets
  HANDLE_EDITOR_PLUGINS = 129, // Permission for municipal administrators to enable/disable editor plugins in municipality
  VIEW_DELETED_SECURE_FILES = 130, // Permission to view deleted secure files
  RESTORE_DELETED_SECURE_FILES = 131, // Permission to restore deleted secure files
  JOURNALING_TO_ESDH = 132, // Permission to export documents to ESDH for journaling
  HANDLE_FILE_SENT_TO_ESDH = 133, // Permission to handle ESDH export data
  HANDLE_ADDITIONAL_MASTER_DATA_REVISION = 134, // Permission to handle user additional master data response revision
  HANDLE_CONSENT_REVISION = 135, // Permission to handle user consent response revision
  HANDLE_MUNICIPAL_FILE_SENT_TO_ESDH = 136, // Permission to handle file sent to ESDH-system in municipality
  HANDLE_ESDH_CONFIGURATION = 137, // Permission to handle ESDH-system configuration
}
