export default {
  changed_by: 'Ændret af',
  changed_at: 'Ændrings tidspunkt',
  class_room: 'Klasse eller stue',
  year: 'Årgang',
  institution: 'Institution',
  yes: 'Ja',
  no: 'Nej',
  number_of_selected: '{number} ud af {total} valgt',
  not: 'ikke',
  locked: 'låst',
  title: 'Titel',
  category: 'Kategori',
  description: 'Beskrivelse',
  child: 'Barn',
  edited: 'Redigeret',
  children_summary: '{name} ({metadata}) + {extra} andre',
  and: 'og',
  other: '{count} andre',
  fold_out: 'Fold ud',
  share: 'Del',
  export: 'Eksporter',
  day: 'dag',
  daily: 'dagligt',
  month: 'i måneden',
  occur: 'Forekommer',
  every: 'hver',
  end_after: 'og slutter efter {total} gentagelser',
  and_end: 'og slutter efter',
  number_of_weeks: '1 uge | {count} uger',
  number_of_months: '1 måned | {count} måneder',
  deleted: 'Slettet',
  all: 'Alle',
  unread: 'Ulæste',
  filter_locked: 'Låste',
  search_by_child: 'Søg efter barn',
  search_by_category: 'Søg efter kategori',
  employee_profile: 'Medarbejderprofiler',
  adjust_expired_date: 'Justér synlig til {option}',
  chosen_expired_date: 'Synlig til dato {expireDate}',
  sort_by_created_date: 'Oprettelsesdato',
  sort_by_album_name: 'Navn',
  sort_by_newest_media: 'Nyeste medier',
  sort_first: 'først',
  sort_last: 'sidst',
  sort_a_z: 'a til z',
  sort_z_a: 'z til a',
  sort_asc:'stigende',
  sort_dsc: 'faldende',
};
