export const storageKeyTypes = Object.freeze({
  calendarFilters: 'calendarFilters',
  draftPosts: 'draftPosts',
  messageThreadView: 'messageThreadView',
  messageThreadViewDefaultWidth: 'messageThreadViewDefaultWidth',
  messageFolderMenuVisible: 'messageFolderMenuVisible',
  messageFolderMenuDefaultWidth: 'messageFolderMenuDefaultWidth',
  messageSelectedFolder: 'messageSelectedFolder',
  messageCollapsedInboxes: 'messageCollapsedInboxes',
});
