import { types } from '../../../src/store/types/types';
import Vue from 'vue';
import { portal } from '../../assets/plugins/axios/axios.js';
import { documentCategories } from '../../enums/documentCategories';
import { documentCategoriesBackend } from '../../enums/documentCategoriesBackend';
import { httpStatus } from '../../enums/httpStatus';
import isEqual from 'lodash/isEqual';
import { exportingStatus } from '../../enums/exportingStatus';
import axios from 'axios';
import { messageOwnerTypes } from '../../enums/messageOwnerTypes';

const state = {
  documentItems: [],
  shareableDocumentItems: {},
  liveDocument: {},
  lastSaveDocument: {},
  revisionData: {},
  selectedItem: null,
  secureDocumentRegardingInstitutionProfile: [],
  archiveDocumentTrack: null,
  archiveDocumentProgress: null,
  documentCategoriesAndTemplates: [],
  isLoadingDocumentTemplatesAndCategories: false,
};

const getters = {
  [types.GET_DOCUMENTS]: state => state.documentItems ?? [],
  [types.GET_SHAREABLE_SECURE_DOCUMENTS]: state => state.shareableDocumentItems,
  [types.GET_DOCUMENTS_LIVE]: state => state.liveDocument,
  [types.GET_DOCUMENTS_IS_DIRTY]: state => {
    if (Object.getOwnPropertyNames(state.liveDocument).length === 0) {
      return false;
    } // new document
    if (Object.getOwnPropertyNames(state.lastSaveDocument).length === 0) {
      return false;
    } // hasn't been saved yet
    return !isEqual(state.liveDocument, state.lastSaveDocument);
  },
  [types.GET_DOCUMENTS_REVISION]: state => state.revisionData,
  [types.GET_SELECTED_ITEM]: state => state.selectedItem,
  [types.GET_SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE]: state => state.secureDocumentRegardingInstitutionProfile,
  [types.GET_ARCHIVE_DOCUMENT_TRACK]: state => state.archiveDocumentTrack,
  [types.GET_ARCHIVE_DOCUMENT_PROGRESS]: state => state.archiveDocumentProgress,
  [types.GET_DOCUMENT_CATEGORIES_AND_TEMPLATES]: state => state.documentCategoriesAndTemplates,
  [types.GET_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING]: state => state.isLoadingDocumentTemplatesAndCategories,
};

const mutations = {
  [types.MUTATE_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING]: (state, isLoading) => {
    state.isLoadingDocumentTemplatesAndCategories = isLoading;
  },
  [types.MUTATE_GET_DOCUMENTS]: (state, payload) => {
    for (const item of payload.documents) {
      item.canEditOriginal = item.canEdit;
      if (item.category === documentCategoriesBackend.OPENTEMPLATE) {
        item.category = documentCategories.OPENTEMPLATE;
      }
    }
    const categories = [];
    for (let item of payload.filters.documentCategories) {
      if (item === documentCategoriesBackend.OPENTEMPLATE) {
        item = documentCategories.OPENTEMPLATE;
      }
      categories.push(item);
    }
    payload.filters.documentCategories = categories;
    state.documentItems = payload;
  },
  [types.MUTATE_RESET_SHAREABLE_DOCUMENT_ITEMS]: state => {
    state.shareableDocumentItems = {};
  },
  [types.MUTATE_GET_SHAREABLE_DOCUMENTS]: (state, payload) => {
    for (const item of payload.documents) {
      item.canEditOriginal = item.canEdit;
      if (item.category === documentCategoriesBackend.OPENTEMPLATE) {
        item.category = documentCategories.OPENTEMPLATE;
      }
    }
    const categories = [];
    for (let item of payload.filters.documentCategories) {
      if (item === documentCategoriesBackend.OPENTEMPLATE) {
        item = documentCategories.OPENTEMPLATE;
      }
      categories.push(item);
    }
    payload.filters.documentCategories = categories;
    state.shareableDocumentItems = payload;
  },
  [types.MUTATE_GET_MORE_DOCUMENTS]: (state, payload) => {
    const currentDocuments = state.documentItems.documents;
    for (const item of payload.documents) {
      item.canEditOriginal = item.canEdit;
      if (item.category === documentCategoriesBackend.OPENTEMPLATE) {
        item.category = documentCategories.OPENTEMPLATE;
      }
    }
    const categories = [];
    for (let item of payload.filters.documentCategories) {
      if (item === documentCategoriesBackend.OPENTEMPLATE) {
        item = documentCategories.OPENTEMPLATE;
      }
      categories.push(item);
    }
    payload.filters.documentCategories = categories;
    payload.documents = currentDocuments.concat(payload.documents);
    state.documentItems = payload;
  },
  [types.MUTATE_GET_MORE_SHAREABLE_DOCUMENTS]: (state, payload) => {
    const currentDocuments = state.shareableDocumentItems.documents;
    for (const item of payload.documents) {
      item.canEditOriginal = item.canEdit;
      if (item.category === documentCategoriesBackend.OPENTEMPLATE) {
        item.category = documentCategories.OPENTEMPLATE;
      }
    }
    const categories = [];
    for (let item of payload.filters.documentCategories) {
      if (item === documentCategoriesBackend.OPENTEMPLATE) {
        item = documentCategories.OPENTEMPLATE;
      }
      categories.push(item);
    }
    payload.filters.documentCategories = categories;
    payload.documents = currentDocuments.concat(payload.documents);
    state.shareableDocumentItems = payload;
  },
  [types.MUTATE_DOCUMENTS_SET_LIVE]: (state, payload) => {
    if (payload.category === documentCategoriesBackend.OPENTEMPLATE) {
      payload.category = documentCategories.OPENTEMPLATE;
    }
    Vue.set(state, 'liveDocument', payload);
  },
  [types.MUTATE_DOCUMENTS_SET_LAST_SAVE]: (state, payload) => {
    Vue.set(state, 'lastSaveDocument', Object.assign({}, payload));
  },
  [types.MUTATE_GET_DOCUMENT_REVISIONS]: (state, payload) => {
    state.revisionData = payload;
  },
  [types.MUTATE_GET_MORE_DOCUMENT_REVISIONS]: (state, payload) => {
    payload.documentRevisionDtos = state.revisionData.documentRevisionDtos.concat(payload.documentRevisionDtos);
    state.revisionData = payload;
  },
  [types.MUTATE_DOCUMENTS_SET_SELECTED_ITEM]: (state, payload) => {
    Vue.set(state, 'selectedItem', payload);
  },
  [types.MUTATE_SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE]: (state, payload) => {
    state.secureDocumentRegardingInstitutionProfile = payload;
  },
  [types.MUTATE_ARCHIVE_DOCUMENT_TRACK]: (state, payload) => {
    state.archiveDocumentTrack = payload;
  },
  [types.MUTATE_ARCHIVE_DOCUMENT_PROGRESS]: (state, payload) => {
    state.archiveDocumentProgress = payload;
  },
  [types.MUTATE_UPDATE_DOCUMENT_SHARED_WITH]: (state, payload) => {
    // single update
    if (payload.documentIds.length === 1) {
      const document = state.documentItems.documents.find(doc => doc.id == payload.documentIds[0]);
      if (document) {
        document.sharedWithGroups = [];
        document.sharedWithInstitutionProfiles = [];
        for (const sharedWith of payload.currentShared) {
          if (sharedWith.profileId) {
            document.sharedWithInstitutionProfiles.push({
              canEdit: sharedWith.canEdit,
              name: sharedWith.displayName,
              id: sharedWith.profileId,
            });
          } else {
            document.sharedWithGroups.push({
              canEdit: sharedWith.canEdit,
              name: sharedWith.groupName ? sharedWith.groupName : sharedWith.displayName,
              id: sharedWith.groupId,
            });
          }
        }
      }
    } else {
      // multiple update
      for (const documentId of payload.documentIds) {
        const document = state.documentItems.documents.find(doc => doc.id == documentId);
        if (document) {
          for (const sharedWith of payload.currentShared) {
            if (
              sharedWith.profileId &&
              !document.sharedWithInstitutionProfiles.find(profile => profile.id == sharedWith.profileId)
            ) {
              document.sharedWithInstitutionProfiles.push({
                canEdit: sharedWith.canEdit,
                name: sharedWith.displayName,
                id: sharedWith.profileId,
              });
            } else if (sharedWith.groupId && !document.sharedWithGroups.find(group => group.id == sharedWith.groupId)) {
              document.sharedWithGroups.push({
                canEdit: sharedWith.canEdit,
                name: sharedWith.groupName,
                id: sharedWith.groupId,
              });
            }
          }
        }
      }
    }
  },
  [types.MUTATE_UPDATE_DOCUMENT_IN_LIST]: (state, payload) => {
    if (state.documentItems.documents != null) {
      const index = state.documentItems.documents.findIndex(d => d.id == payload.id);
      if (index != null) {
        state.documentItems.documents[index].isLocked = payload.isLocked;
        state.documentItems.documents[index].canEdit = payload.canEdit;
        state.documentItems.documents[index].canEditLockedStatus = payload.canEditLockedStatus;
      }
    }
  },
  [types.MUTATE_RESET_DOCUMENT_ITEMS]: state => {
    state.documentItems = [];
  },
  [types.MUTATE_DOCUMENT_CATEGORIES_AND_TEMPLATES]: (state, payload) => {
    state.documentCategoriesAndTemplates = payload.documentCategoriesAndTemplates;
  },
};

const actions = {
  [types.ACTION_DOCUMENTS_SET_LIVE]: ({ commit }, payload) => {
    commit(types.MUTATE_DOCUMENTS_SET_LIVE, payload);
  },
  [types.LOCK_DOCUMENT]: ({ commit }, payload) =>
    portal
      .post('?method=documents.updateLockedStatus', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, payload.isLocked ? 'DOCUMENTS_LOCK_SUCCESS' : 'DOCUMENTS_UNLOCK_SUCCESS');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_LOCKED_STATUS');
      }),
  [types.ACTION_GET_DOCUMENTS]: ({ commit }, payload) => {
    const config = {
      cancelToken: payload.cancelToken,
    };
    commit(types.MUTATE_RESET_DOCUMENT_ITEMS);
    payload.params.filterDocumentCategories = prepareDocumentCategories(payload.params.filterDocumentCategories);
    return portal
      .post('?method=Documents.getSecureDocuments', payload.params, config)
      .then(response => {
        commit(types.MUTATE_GET_DOCUMENTS, response.data.data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      });
  },
  [types.ACTION_GET_DELETED_DOCUMENTS_ADMIN]: ({ commit }, payload) => {
    const config = {
      cancelToken: payload.cancelToken,
    };
    commit(types.MUTATE_RESET_DOCUMENT_ITEMS);
    payload.params.filterDocumentCategories = prepareDocumentCategories(payload.params.filterDocumentCategories);
    return portal
      .post('?method=documentsAdmin.getDeletedSecureDocuments', payload.params, config)
      .then(response => {
        commit(types.MUTATE_GET_DOCUMENTS, response.data.data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DELETED_SECURE_DOCUMENTS');
      });
  },
  [types.ACTION_GET_SHAREABLE_DOCUMENTS]: ({ commit }, payload) => {
    payload.filterDocumentCategories = prepareDocumentCategories(payload.filterDocumentCategories);
    commit(types.MUTATE_RESET_SHAREABLE_DOCUMENT_ITEMS);
    return portal
      .post('?method=Documents.getShareableSecureDocuments', payload)
      .then(response => {
        commit(types.MUTATE_GET_SHAREABLE_DOCUMENTS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      });
  },
  [types.ACTION_GET_MORE_DOCUMENTS]: ({ commit }, payload) => {
    const config = {
      cancelToken: payload.cancelToken,
    };
    payload.params.filterDocumentCategories = prepareDocumentCategories(payload.params.filterDocumentCategories);
    return portal
      .post('?method=Documents.getSecureDocuments', payload.params, config)
      .then(response => {
        commit(types.MUTATE_GET_MORE_DOCUMENTS, response.data.data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      });
  },
  [types.ACTION_GET_MORE_DELETED_DOCUMENTS_ADMIN]: ({ commit }, payload) => {
    const config = {
      cancelToken: payload.cancelToken,
    };
    payload.params.fitlerDocumentCategories = prepareDocumentCategories(payload.params.filterDocumentCategories);
    return portal
      .post('?method=documentsAdmin.getDeletedSecureDocuments', payload.params, config)
      .then(response => {
        commit(types.MUTATE_GET_MORE_DOCUMENTS, response.data.data);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DELETED_SECURE_DOCUMENTS');
      });
  },
  [types.ACTION_GET_MORE_SHAREABLE_DOCUMENTS]: ({ commit }, payload) => {
    payload.filterDocumentCategories = prepareDocumentCategories(payload.filterDocumentCategories);
    return portal
      .post('?method=Documents.getShareableSecureDocuments', payload)
      .then(response => {
        commit(types.MUTATE_GET_MORE_SHAREABLE_DOCUMENTS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      });
  },
  [types.ACTIONS_DOCUMENTS_SECURE_INTERNAL_CREATE]: ({ commit, state, rootState }) => {
    const payload = state.liveDocument;
    payload.title = payload.title || '';
    payload.content = payload.content || '';
    if (state.liveDocument.category === documentCategories.OPENTEMPLATE) {
      state.liveDocument.category = documentCategoriesBackend.OPENTEMPLATE;
    }
    if (rootState.messages.isCreatingSecureFile) {
      let messageIds = [];
      const commonInboxId =
        rootState.messages.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX
          ? rootState.messages.chosenFolderAndMailOwner.mailOwnerId
          : null;
      if (!rootState.messages.isSelectedAllMessages) {
        messageIds = rootState.messages.messages
          .filter(message => message.selected === true)
          .map(message => message.id);
      }
      payload.attachedThread = {
        threadId: rootState.messages.shownSubscription.id,
        messageIds,
        commonInboxId,
      };
    }
    return portal
      .post('?method=documents.createInternalSecureDocument', payload)
      .then(response => {
        commit(types.MUTATE_DOCUMENTS_SET_LAST_SAVE, response.data.data);
        commit(types.MUTATE_SUCCESS_TEXT, 'DOCUMENTS_CREATED_SUCCESSFULLY');
        return response.data;
      })
      .catch(error => {
        const unshareableGuardianIds = getUnsharableGuardianIdsFromError(error);
        if (unshareableGuardianIds.length > 0) {
          throw unshareableGuardianIds;
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_SECURE_DOCUMENTS');
      });
  },
  [types.LOAD_IMPLICIT_SHARINGS]: ({ commit }, payload) =>
    portal
      .post('?method=Documents.getImplicitSharings', payload)
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DOCUMENT_REVISIONS');
      }),
  [types.ACTION_GET_DOCUMENT_CATEGORIES_AND_TEMPLATES]: ({ commit }, payload) => {
    commit(types.MUTATE_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING, true);
    return portal
      .get('?method=Documents.getDocumentCategoriesAndTemplates', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_DOCUMENT_CATEGORIES_AND_TEMPLATES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DOCUMENT_CATEGORIES_AND_TEMPLATES');
      })
      .finally(() => {
        commit(types.MUTATE_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING, false);
      });
  },
  [types.ACTIONS_DOCUMENTS_SECURE_INTERNAL_UPDATE]: ({ commit, state }) => {
    const payload = state.liveDocument;
    payload.title = payload.title || '';
    payload.content = payload.content || '';
    if (state.liveDocument.category === documentCategories.OPENTEMPLATE) {
      state.liveDocument.category = documentCategoriesBackend.OPENTEMPLATE;
    }
    return portal
      .post('?method=documents.updateInternalSecureDocument', payload)
      .then(response => {
        if (response.data.data.isConflict) {
          commit(types.MUTATE_DOCUMENTS_SET_LAST_SAVE, response.data.data);
        }

        if (response.data.data !== false) {
          if (payload.toastrTextKey) {
            commit(types.MUTATE_SUCCESS_TEXT, payload.toastrTextKey);
          } else {
            commit(types.MUTATE_SUCCESS_TEXT, 'DOCUMENTS_UPDATED_SUCCESSFULLY');
          }
        }
        return response;
      })
      .catch(error => {
        const unshareableGuardianIds = getUnsharableGuardianIdsFromError(error);
        if (unshareableGuardianIds.length > 0) {
          throw unshareableGuardianIds;
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_SECURE_DOCUMENTS');
      });
  },
  [types.ACTIONS_DOCUMENTS_SECURE_EXTERNAL_CREATE]: ({ commit, state }) => {
    const payload = state.liveDocument;
    payload.title = payload.title || '';
    if (state.liveDocument.category === documentCategories.OPENTEMPLATE) {
      state.liveDocument.category = documentCategoriesBackend.OPENTEMPLATE;
    }
    if (payload.externalFile && payload.externalFile.id) {
      payload.externalFileId = payload.externalFile.id;
    }
    return portal
      .post('?method=documents.createExternalSecureFile', payload)
      .then(response => {
        commit(types.MUTATE_DOCUMENTS_SET_LAST_SAVE, response.data.data);
        commit(types.MUTATE_SUCCESS_TEXT, 'DOCUMENTS_CREATED_SUCCESSFULLY');
        return response.data;
      })
      .catch(error => {
        const unshareableGuardianIds = getUnsharableGuardianIdsFromError(error);
        if (unshareableGuardianIds.length > 0) {
          throw unshareableGuardianIds;
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_SECURE_DOCUMENTS');
      });
  },
  [types.ACTIONS_DOCUMENTS_SECURE_EXTERNAL_UPDATE]: ({ commit, state }) => {
    const payload = state.liveDocument;
    payload.title = payload.title || '';
    if (state.liveDocument.category === documentCategories.OPENTEMPLATE) {
      state.liveDocument.category = documentCategoriesBackend.OPENTEMPLATE;
    }
    if (payload.externalFile && payload.externalFile.id) {
      payload.externalFileId = payload.externalFile.id;
    }
    if (payload.externalFile != null) {
      return portal
        .post('?method=documents.updateExternalSecureFile', payload)
        .then(response => {
          if (response.data.data.isConflict) {
            commit(types.MUTATE_DOCUMENTS_SET_LAST_SAVE, response.data.data);
          }
          commit(types.MUTATE_SUCCESS_TEXT, 'DOCUMENTS_UPDATED_SUCCESSFULLY');
          return response;
        })
        .catch(error => {
          const unshareableGuardianIds = getUnsharableGuardianIdsFromError(error);
          if (unshareableGuardianIds.length > 0) {
            throw unshareableGuardianIds;
          }
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_SECURE_DOCUMENTS');
        });
    }
  },
  [types.ACTIONS_GET_DOCUMENT_REVISIONS]: ({ commit }, payload) => {
    const params = payload;
    commit(types.MUTATE_GET_DOCUMENT_REVISIONS, {});
    return portal
      .get('?method=Documents.getDocumentRevisions', {
        params,
      })
      .then(response => {
        commit(types.MUTATE_GET_DOCUMENT_REVISIONS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DOCUMENT_REVISIONS');
      });
  },
  [types.ACTIONS_GET_MORE_DOCUMENT_REVISIONS]: ({ commit }, payload) => {
    const params = payload;
    return portal
      .get('?method=Documents.getDocumentRevisions', {
        params,
      })
      .then(response => {
        commit(types.MUTATE_GET_MORE_DOCUMENT_REVISIONS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DOCUMENT_REVISIONS');
      });
  },
  [types.ACTIONS_GET_DOCUMENT_REVISION]: ({ commit }, payload) => {
    const params = payload;
    return portal
      .get('?method=Documents.getDocumentRevision', {
        params,
      })
      .then(response => {
        commit(types.MUTATE_DOCUMENTS_SET_LIVE, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DOCUMENT_REVISIONS');
      });
  },
  [types.ACTIONS_DOCUMENTS_SET_SELECTED_ITEM]: ({ commit }, payload) => {
    commit(types.MUTATE_DOCUMENTS_SET_SELECTED_ITEM, payload);
  },
  [types.ACTION_EDIT_SHARINGS]: ({ commit }, payload) =>
    portal
      .post('?method=documents.updateSharings', payload)
      .then(() => {})
      .catch(error => {
        // AULA-34922: error with subCode 13 will be handled in the component itself
        if (error.response.data.status.subCode !== 13) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_SHARINGS');
        }

        return error;
      }),
  [types.ACTION_LOAD_INTERNAL_DOCUMENT]: ({ commit }, payload) => {
    const params = payload;
    return portal
      .get('?method=documents.getInternalSecureDocument', {
        params,
      })
      .then(response => {
        if (payload.isUpdateInList) {
          commit(types.MUTATE_UPDATE_DOCUMENT_IN_LIST, response.data.data);
        } else {
          commit(types.MUTATE_DOCUMENTS_SET_LIVE, response.data.data);
        }
        return response.data.data;
      })
      .catch(error => {
        if (error.response.data.status.code == httpStatus.FORBIDDEN && error.response.data.status.subCode == 1) {
          commit(types.MUTATE_ERROR_TEXT, 'NO_PERMISSION_TO_VIEW_SECURE_DOCUMENT');
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENT');
        }
        throw error;
      });
  },
  [types.ACTION_LOAD_EXTERNAL_DOCUMENT]: ({ commit }, payload) => {
    const params = payload;
    return portal
      .get('?method=documents.getExternalSecureFile', {
        params,
      })
      .then(response => {
        if (payload.isUpdateInList) {
          commit(types.MUTATE_UPDATE_DOCUMENT_IN_LIST, response.data.data);
        } else {
          commit(types.MUTATE_DOCUMENTS_SET_LIVE, response.data.data);
        }
        return response.data.data;
      })
      .catch(error => {
        if (error.response.data.status.code == httpStatus.FORBIDDEN && error.response.data.status.subCode == 1) {
          commit(types.MUTATE_ERROR_TEXT, 'NO_PERMISSION_TO_VIEW_SECURE_DOCUMENT');
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENT');
        }
        return error.response.data.status;
      });
  },
  [types.ACTION_DISCARD_DOCUMENT]: ({ commit }) => {
    commit(types.MUTATE_DOCUMENTS_SET_LIVE, {});
    commit(types.MUTATE_DOCUMENTS_SET_LAST_SAVE, {});
  },
  [types.ACTION_GET_SECURE_DOCUMENTS_REGARDING_INSTITUTION_PROFILE]: ({ commit }, payload) =>
    portal
      .get('?method=documentsAdmin.getSecureDocumentsRegardingInstitutionProfile', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      }),
  [types.ACTION_CREATE_ARCHIVE_SECURE_DOCUMENTS]: ({ commit }, payload) =>
    portal
      .post('?method=documents.createArchiveForMultipleSecureDocuments', payload)
      .then(response => {
        commit(types.MUTATE_ARCHIVE_DOCUMENT_TRACK, response.data.data);
      })
      .catch(error => error),
  [types.ACTION_TRACK_ARCHIVE_SECURE_DOCUMENTS]: ({ commit }, payload) =>
    portal
      .post('?method=documents.trackCreateArchiveForMultipleSecureDocumentsRequest', payload)
      .then(response => {
        if (response.data.data.status.code === exportingStatus.FAILED) {
          throw response.data.data.status;
        }
        commit(types.MUTATE_ARCHIVE_DOCUMENT_PROGRESS, response.data.data);
      })
      .catch(error => {
        throw error;
      }),
  [types.ACTION_DELETE_DOCUMENT]: ({ commit }, payload) =>
    portal
      .post('?method=documents.deleteDocument', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'API_SUCCESS_DELETE_DOCUMENT');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_DOCUMENT');
      }),
  [types.ACTION_RESTORE_DELETED_DOCUMENT_ADMIN]: ({ commit }, payload) =>
    portal
      .post('?method=documentsAdmin.restoreDeletedDocument', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'API_SUCCESS_RESTORE_DELETED_DOCUMENT');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_RESTORE_DELETED_DOCUMENT');
      }),
};

const getUnsharableGuardianIdsFromError = errorResponseData => {
  let result = [];

  if (errorResponseData.response && errorResponseData.response.data && errorResponseData.response.data.status) {
    const errorInformation = errorResponseData.response.data.status.errorInformation;
    if (errorInformation && errorInformation.notShareableGuardianIds.length > 0) {
      result = errorInformation.notShareableGuardianIds;
    }
  }

  return result;
};

export default {
  state,
  mutations,
  actions,
  getters,
};

const prepareDocumentCategories = filterDocumentCategories => {
  if (Array.isArray(filterDocumentCategories) && filterDocumentCategories.length > 0) {
    for (let i = 0; i < filterDocumentCategories.length; i++) {
      if (filterDocumentCategories[i] === documentCategories.OPENTEMPLATE) {
        filterDocumentCategories[i] = documentCategoriesBackend.OPENTEMPLATE;
      }
    }
  }
  return filterDocumentCategories;
};
