export default {
  draft: 'kladde',
  thread: '{count} beskedsamtale | {count} beskedsamtaler',
  group_replies: 'Gruppesamtale med {count} direkte svar',
  created_by_you: 'Oprettet af dig',
  bcc_created_by_you: 'BCC-besked oprettet af dig',
  select_this_message: 'Vælg denne meddelelse',
  sensitive_content: 'Følsomt indhold',
  read: 'Læst',
  unread: 'Ulæst',
  remove_star: 'Fjern stjerne',
  mark_with_star: 'Marker med stjerne',
  remove_mark: 'Fjern stjerne',
  mute: 'Slå notifikationer fra',
  un_mute: 'Slå notifikationer til',
  forward: 'Videresend',
  forward_message: 'Videresend besked',
  forward_thread: 'Videresend samtalen',
  forward_prepend: 'Vs.',
  answer_directly: 'Svar afsender direkte',
  thread_starter: 'Samtalestarter',
  create_message: 'Opret besked',
  thread_metadata_1: 'Samtalen er startet af {creator} ',
  thread_metadata_2: '{startedAt}',
  personal_inboxes: 'Personlig postkasse',
  common_inboxes: 'Fællespostkasser',
  inboxes: 'Indbakke',
  move: 'Flyt',
  add_more_recipients: 'Tilføj flere modtagere',
  response_option: 'Svarmuligheder',
  delete: 'Slet',
  replied_message: 'Besked som du svarer på',
  forwarded_message: 'Besked som du videresender',
  system_message_forwarded_message: 'Ovenstående besked er videresendt',
  system_message_forwarded_thread: 'Ovenstående beskeder er videresendt',
  system_message_description: 'Samtalen er oprindeligt fra {date} og var sendt til: {recipients}',
  system_message_reply: 'Denne besked er oprindeligt fra en anden samtale',
  functionalities: 'Besked funktionaliteter',
  warning_deletion_of_primary_thread:
    "<p>Beskedsamtalen slettes kun for dig og du vil ikke længere se nyt indhold i samtalen.<br/>Du vil kunne genfinde den hidtidige korrespondance i mappen 'Slettet post'.<p/><p>Når du sletter den oprindelige gruppesamtale, vil de underliggende beskedsamtaler ikke længere fremstå grupperet.<p/><p>Ønsker du at slette den oprindelige gruppesamtale?<p/>",
  warning_moving_of_primary_thread:
    'OBS: Når du flytter den oprindelige gruppesamtale, vil de underliggende beskedsamtaler ikke længere fremstå grupperet. <br/> Vælg hvilken mappe, du vil flytte beskederne til: <br/>',
  warning_moving_of_regular_thread: 'Vælg hvilken mappe, du vil flytte beskederne til:',
  save_as_secure_file: 'Gem som ny sikker fil',
  choose_document_type: 'Vælg om du vil gemme valgte beskeder som note eller dokument',
  new_folder: 'Ny mappe',
  rename_folder: 'Omdøb mappe',
  folder_name_placeholder: 'Skriv navn på mappe',
  message_moved: 'Beskeden er flyttet',
  folder_created: 'Mappen blev oprettet',
  folder_deleted: 'Mappen blev slettet',
  folder_renamed: 'Navnet på mappen blev ændret.',
  folder_name_warning: 'Navnet på mappen kan max være 100 tegn',
  primary_thread_moving_warning:
    'Når du flytter den oprindelige gruppesamtale, vil de underliggende beskedsamtaler ikke længere fremstå grupperet. <br/> Ønsker du at fortsætte?',
  create_new_folder: 'Opret ny mappe',
  view_folders: 'Vis undermapper',
  edit_folder: 'Rediger mappe',
};
