export default {
  continue: 'Vil du fortsætte?',
  want_to_continue: 'Ønsker du at fortsætte?',
  are_you_sure_you_want_to_continue: 'Er du sikker på, at du vil fortsætte?',
  unsaved_changes:
    'Sidst du ændrede denne sikre fil, var der ændringer der ikke blev gemt.<br/> Vil du fortsætte hvor du slap?',
  clear_everything_in_the_field: 'Du er ved at rydde alt i feltet.',
  remove_all_organizers_from_simple_event:
    'Du er ved at fjerne alle medarrangører fra begivenheden. Det betyder, at de ikke længere vil kunne se eller redigere i begivenheden.',
  remove_all_organizers_from_meeting_event:
    'Du er ved at fjerne alle medarrangører fra samtalen. Det betyder, at de ikke længere vil kunne se eller redigere i samtalen.',
  action_step_up_required: 'For at udføre denne handling skal du logge ind på et højere sikkerhedsniveau.',
};
