<template>
  <div class="aula-select" :class="{ 'appended-to-parent': isAppendedToParent }">
    <Icon v-if="showSearchIcon" :name="iconClassEnum.SEARCH" class="search-icon" />
    <el-select
      ref="select"
      :data-show-search-icon="showSearchIcon"
      v-bind="{ ...$attrs, ...$props }"
      @input="$emit('input', $event)"
      @visible-change="$emit('visible-change', $event)"
      @remove-tag="$emit('remove-tag', $event)"
      @clear="$emit('clear', $event)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      @change="$emit('change', $event)"
    >
      <slot />
    </el-select>
    <AulaButton
      v-if="clearable"
      :aria-label="$t('button.clear_all')"
      :title="$t('button.clear_all')"
      class="button-close"
      variant="link"
      @click="handleClearClick"
    >
      <IconContainer>
        <Icon :name="iconClassEnum.CLOSE" />
      </IconContainer>
    </AulaButton>
    <AulaModal
      ref="clearSelectedUsersAndGroupsWarningModal"
      :ok-text="'BUTTON_YES'"
      @okClicked="emitClear"
      @cancelClicked="$refs.clearSelectedUsersAndGroupsWarningModal.hide()"
    >
      {{ $t('warning.clear_everything_in_the_field') }}
      <br />
      {{ $t('warning.want_to_continue') }}
    </AulaModal>
  </div>
</template>
<script>
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import IconContainer from '../IconContainer.vue';
import AulaButton from '../AulaButton.vue';

export default {
  components: { AulaButton, IconContainer, Icon },
  props: {
    defaultFirstOption: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showSearchIcon: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  emits: ['emitClear'],
  data() {
    return {
      selectRef: null,
      inputElement: null,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isAppendedToParent() {
      return this.$attrs['popper-append-to-body'] === false;
    },
    showsPopup() {
      if (this.selectRef != null) {
        return this.selectRef.visible && this.selectRef.emptyText !== false;
      }

      return false;
    },
    activeDescendant() {
      if (this.loading === false && this.selectRef != null) {
        const optionIndex = this.selectRef.hoverIndex;
        if (optionIndex > -1) {
          const option = this.selectRef.options[optionIndex];
          if (option != null) {
            return option.$el.id;
          }
        }
      }
      return '';
    },
  },
  watch: {
    loading(loading) {
      if (loading === false || this.selectRef == null) {
        return;
      }

      if (this.defaultFirstOption === true && this.selectRef.options.length > 0) {
        this.$nextTick(() => {
          this.selectRef.checkDefaultFirstOption();
        });
      }
    },
    showsPopup(showsPopup) {
      if (this.inputElement != null) {
        this.inputElement.setAttribute('aria-haspopup', showsPopup.toString());
      }
    },
    activeDescendant(activeDescendant) {
      if (this.inputElement != null) {
        if (activeDescendant.length > 0) {
          this.inputElement.setAttribute('aria-activedescendant', activeDescendant);
        }
      }
    },
  },
  mounted() {
    this.selectRef = this.$refs.select;
    this.inputElement = this.selectRef.$el.querySelector('div.el-select__tags > input, .el-input__inner');
    this.setupAria();
  },
  methods: {
    setupAria() {
      this.inputElement.setAttribute('role', 'combobox');
      this.inputElement.setAttribute('aria-autocomplete', 'list');
      this.selectRef.popperElm
        .querySelector('ul.el-scrollbar__view.el-select-dropdown__list')
        .setAttribute('role', 'listbox');
      if (typeof this.$attrs['aria-label'] === 'string') {
        this.inputElement.setAttribute('aria-label', this.$attrs['aria-label']);
      }
    },
    focus() {
      this.$refs.select.focus();
    },
    handleClearClick() {
      this.$refs.clearSelectedUsersAndGroupsWarningModal.show();
    },
    emitClear() {
      this.$emit('emitClear');
      this.$refs.clearSelectedUsersAndGroupsWarningModal.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
.aula-select {
  border-radius: var(--aula-select-border-radius, 5px);
  &[disabled='disabled'] {
    --caret-color: var(--color-grey-dark);
  }
  .search-icon {
    position: absolute;
    z-index: 1;
    --font-size: 18px;
    top: 17px;
    right: 25px;
  }
  .button-close {
    position: absolute;
    top: 12px;
    right: 47px;
    height: 28px;
    width: 28px;
    opacity: 0.8;
    background-color: transparent;

    &:hover {
      opacity: 1;
    }

    .icon-container {
      width: 100%;
      height: 100%;
      .aula-icon {
        --font-size: 18px;
      }
    }
  }
  /deep/ .el-select {
    border-color: var(--border-color, transparent);
    border-width: var(--border-width, 1px);
    border-style: var(--border-style, solid);

    .el-input .el-select__caret {
      color: var(--caret-color, var(--color-primary-darker));
      font-weight: var(--caret-weight, bold);
      font-size: var(--caret-size, 16px);
    }

    &[data-show-search-icon] {
      .el-input .el-select__caret {
        display: none;
      }
    }
  }
}

/* el-select Popper configuration */
.appended-to-parent /deep/ .el-select-dropdown {
  position: fixed !important;
  top: auto !important;
  left: auto !important;
}
</style>
