export default {
  document: 'Dokument',
  note: 'Note',
  journalise_to_esdh_system: 'Journaliser i ESDH-system',
  no_esdh_system_specified: 'Din kommune har endnu ikke opsat et modtagende ESDH-system.',
  exceeded_regarding_children_warning_1:
    'Følgende filer kan ikke blive sendt til journalisering i ESDH-system, fordi de omhandler flere end 28 børn.',
  exceeded_regarding_children_warning_2:
    'Nævnte filer bliver fjernet fra listen og bliver ikke sendt til journalisering i ESDH-system.',
  sending_documents_to_esdh_system_information: 'Du er ved at sende følgende filer til din kommunes ESDH-system.',
  total_selected_files: 'Antal filer markeret til journalisering i ESDH-system: {total}',
  journalization_in_progress: 'Journalisering i gang',
  journalization_completed: 'Filen er journaliseret i ESDH-systemet',
  journalized: 'Journaliseret',
  journalization_failed: 'Journalisering fejlede',
  no_regarding_children_warning_1:
    'Følgende filer kan ikke sendes til journalisering i ESDH, fordi der ikke er mindst et barn tilknyttet.',
  no_regarding_children_warning_2:
    'De filer bliver derfor fjernet fra listen af filer, der bliver journaliseret i ESDH-system.',
  sending_document_warning: 'Du er ved at sende {number} filer til ESDH-system.',
  document_locked_warning:
    'Filerne vil derefter blive {lockedText}, hvilket betyder, at du IKKE vil kunne redigere filerne efter afsendelse.',
  unlock_being_exported_document_warning:
    'Denne fil er i gang med at blive journaliseret, derfor kan den ikke låses op på nuværende tidspunkt.',
  export_being_journalized_again_warning_1:
    'Det er ikke muligt at journalisere dokumenter, der er i gang med at blive journaliseret.',
  export_being_journalized_again_warning_2:
    'Nævnte filer er allerede sendt til journalisering i ESDH-systemet og bliver derfor fjernet fra listen.',
  file_is_being_journalized: 'Filen er under journalisering',
  can_not_unlock_file_being_exported:
    'Denne fil er i gang med at blive journaliseret, derfor kan den ikke låses op på nuværende tidspunkt.',
  can_not_journalize_already_journalized_document:
    'Denne fil er allerede journaliseret og kan ikke blive journaliseret igen.',
  no_export_permission_warning:
    'Du har valgt en eller flere filer, du ikke har rettighed til at journalisere, som vil blive fjernet fra eksportlisten.',
  no_recipients_configured_warning:
    'Du har valgt en eller flere filer, i en kommune hvor journalisering ikke er sat op af en administrator, hvorfor nedenstående filer bliver fjernet fra journaliseringslisten.',
  no_files: 'Der er ingen filer.',
  remove_association: 'Fjern tilknytning',
  remove_association_without_edit_right_warning:
    'Din tilknytning til følgende filer bliver {notText} fjernet, da du ikke har redigeringsrettighed til dem:',
  continue_without_listed_files_confirmation: 'Vil du fortsætte uden nævnte filer?',
  remove_association_warning_1: 'Hvis du fortsætter, bliver din tilknytning til de valgte sikre filer fjernet.',
  remove_association_warning_2: 'Det betyder, at du mister adgang og redigeringsret til dem.',
  number_of_selected: 'Antal valgte filer: {number}',
  functionalities: 'Fil funktionaliteter',
  select_all: 'Vælg alle dokumenter',
  exceeded_pdf_export_warning:
    'Du kan kun eksportere op til {total} filer. Hvis du vil fortsætte, skal du afmarkere mindst {remove} filer.',
};
