// Interface for all getters, mutations and actions

// Getters
export const GET_EVENTS = 'calendar/GET_EVENTS';
export const CALENDAR_GET_EVENT_TYPES_FOR_FILTER = 'calendar/CALENDAR_GET_EVENT_TYPES_FOR_FILTER';
export const GET_WARNING_CONFLICT_EVENT_BY_ATTENDEES = 'calendar/GET_WARNING_CONFLICT_EVENT_BY_ATTENDEES';
export const GET_CURRENT_EVENT = 'calendar/GET_CURRENT_EVENT';
export const GET_IMPORTANT_DATES = 'calendar/GET_IMPORTANT_DATES';
export const GET_BIRTHDAY_EVENTS = 'calendar/GET_BIRTHDAY_EVENTS';
export const GET_EVENT_SELECTED_FROM_SEARCH = 'calendar/GET_EVENT_SELECTED_FROM_SEARCH';
export const GET_ASSIGNED_DELEGATED_ACCESSES = 'calendar/GET_ASSIGNED_DELEGATED_ACCESSES';
export const GET_MY_DELEGATED_ACCESSES = 'calendar/GET_MY_DELEGATED_ACCESSES';
export const GET_DATETIME_SELECTED_BY_CLICKED_TIMESLOT = 'calendar/GET_DATETIME_SELECTED_BY_CLICKED_TIMESLOT';
export const GET_CURRENT_WEEK = 'calendar/GET_CURRENT_WEEK';
export const GET_DELEGATED_CONTEXT = 'calendar/GET_DELEGATED_CONTEXT';
export const GET_CALENDAR_ACTIVE_INST_PROFILE_ID = 'calendar/GET_CALENDAR_ACTIVE_INST_PROFILE_ID';
export const GET_RESOURCES = 'calendar/GET_RESOURCES';
export const GET_MY_VACATION_REQUESTS = 'calendar/GET_MY_VACATION_REQUESTS';
export const GET_UNAVAILABLE_RESOURCES = 'calendar/GET_UNAVAILABLE_RESOURCES';
export const GET_CALENDAR_SYNC_CONSENT = 'calendar/GET_CALENDAR_SYNC_CONSENT';
export const GET_CALENDAR_FEEDS = 'calendar/GET_CALENDAR_FEEDS';
export const GET_VACATION_REQUEST_RESPONSES = 'calendar/GET_VACATION_REQUEST_RESPONSES';
export const GET_MUNICIPAL_CALENDER_FEED = 'calendar/GET_MUNICIPAL_CALENDER_FEED';
export const GET_CALENDAR_FEED_EVENTS = 'calendar/GET_CALENDAR_FEED_EVENTS';
export const IS_LOADING_EVENTS = 'calendar/IS_LOADING_EVENTS';

// Mutations
export const MUTATE_LOAD_EVENTS_BY_PROFILEID = 'calendar/MUTATE_LOAD_EVENTS_BY_PROFILEID';
export const MUTATE_LOAD_EVENTS_FOR_ADMINISTRATION = 'calendar/MUTATE_LOAD_EVENTS_FOR_ADMINISTRATION';
export const MUTATE_WARNING_CONFLICT_EVENT_BY_ATTENDEES = 'calendar/MUTATE_WARNING_CONFLICT_EVENT_BY_ATTENDEES';
export const MUTATE_GET_EVENT_BY_ID = 'calendar/MUTATE_GET_EVENT_BY_ID';
export const MUTATE_LOAD_EVENT_TYPES = 'calendar/MUTATE_LOAD_EVENT_TYPES';
export const MUTATE_LOAD_EVENT_TYPES_FOR_FILTER = 'calendar/MUTATE_LOAD_EVENT_TYPES_FOR_FILTER';
export const MUTATE_LOAD_IMPORTANT_DATES = 'calendar/MUTATE_LOAD_IMPORTANT_DATES';
export const MUTATE_CLEAR_EVENTS = 'calendar/MUTATE_CLEAR_EVENTS';
export const MUTATE_EVENT_SELECTED_FROM_SEARCH = 'calendar/MUTATE_EVENT_SELECTED_FROM_SEARCH';
export const MUTATE_LOAD_ASSIGNED_DELEGATED_ACCESSES = 'calendar/MUTATE_LOAD_ASSIGNED_DELEGATED_ACCESSES';
export const MUTATE_LOAD_MY_DELEGATED_ACCESSES = 'calendar/MUTATE_LOAD_MY_DELEGATED_ACCESSES';
export const MUTATE_DATETIME_SELECTED_BY_CLICKED_TIMESLOT = 'calendar/MUTATE_DATETIME_SELECTED_BY_CLICKED_TIMESLOT';
export const MUTATE_LOAD_BIRTHDAY_EVENTS = 'calendar/MUTATE_LOAD_BIRTHDAY_EVENTS';
export const MUTATE_CURRENT_WEEK = 'calendar/MUTATE_CURRENT_WEEK';
export const MUTATE_LOAD_DELEGATED_CONTEXT = 'calendar/MUTATE_LOAD_DELEGATED_CONTEXT';
export const MUTATE_CALENDAR_ACTIVE_INST_PROFILE_ID = 'calendar/MUTATE_CALENDAR_ACTIVE_INST_PROFILE_ID';
export const MUTATE_LIST_RESOURCES = 'calendar/MUTATE_LIST_RESOURCES';
export const MUTATE_MY_VACATION_REQUESTS = 'calendar/MUTATE_MY_VACATION_REQUESTS';
export const MUTATE_UNAVAILABLE_RESOURCES = 'calendar/MUTATE_UNAVAILABLE_RESOURCES';
export const MUTATE_CALENDAR_SYNC_CONSENT = 'calendar/MUTATE_CALENDAR_SYNC_CONSENT';
export const MUTATE_CALENDAR_FEEDS = 'calendar/MUTATE_CALENDAR_FEEDS';
export const MUTATE_VACATION_REQUEST_RESPONSES = 'calendar/MUTATE_VACATION_REQUEST_RESPONSES';
export const MUTATE_GET_MUNICIPAL_CALENDER_FEED = 'calendar/MUTATE_GET_MUNICIPAL_CALENDER_FEED';
export const MUTATE_MUNICIPAL_CALENDER_FEED = 'calendar/MUTATE_MUNICIPAL_CALENDER_FEED';
export const MUTATE_CALENDAR_FEED_EVENTS = 'calendar/MUTATE_CALENDAR_FEED_EVENTS';
export const MUTATE_RESET_ACTIVE_EVENT = 'caledar/MUTATE_RESET_ACTIVE_EVENT';
export const MUTATE_IS_LOADING_EVENTS = 'calendar/MUTATE_IS_LOADING_EVENTS';

// Actions
export const CREATE_SIMPLE_EVENT = 'calendar/CREATE_SIMPLE_EVENT';
export const CREATE_VACATION_REQUEST = 'calendar/CREATE_VACATION_REQUEST';
export const CREATE_REPEATING_EVENT = 'calendar/CREATE_REPEATING_EVENT';
export const CREATE_TIMESLOT_EVENT = 'calendar/CREATE_TIMESLOT_EVENT';
export const UPDATE_SIMPLE_EVENT = 'calendar/UPDATE_SIMPLE_EVENT';
export const UPDATE_REPEATING_EVENT = 'calendar/UPDATE_REPEATING_EVENT';
export const UPDATE_TIMESLOT_EVENT = 'calendar/UPDATE_TIMESLOT_EVENT';
export const UPDATE_LESSON_EVENT = 'calendar/UPDATE_LESSON_EVENT';
export const SET_CURRENT_WEEK = 'calendar/SET_CURRENT_WEEK';
export const WARNING_CONFLICT_EVENT_BY_ATTENDEES = 'calendar/WARNING_CONFLICT_EVENT_BY_ATTENDEES';
export const CLEAN_WARNING_CONFLICT_EVENT_BY_ATTENDEES = 'calendar/CLEAN_WARNING_CONFLICT_EVENT_BY_ATTENDEES';
export const LOAD_EVENTS_BY_PROFILEID = 'calendar/LOAD_EVENTS_BY_PROFILEID';
export const LOAD_EVENTS_BY_GROUPHOME = 'calendar/LOAD_EVENTS_BY_GROUPHOME';
export const LOAD_EVENTS_FOR_ADMINISTRATION = 'calendar/LOAD_EVENTS_FOR_ADMINISTRATION';
export const LOAD_EVENTS_BY_INSTITUTIONS = 'calendar/LOAD_EVENTS_BY_INSTITUTIONS';
export const GET_EVENT_BY_ID = 'calendar/GET_EVENT_BY_ID';
export const RESPOND_TO_SIMPLE_EVENT = 'calendar/RESPOND_TO_SIMPLE_EVENT';
export const RESPOND_TO_TIMESLOT_EVENT = 'calendar/RESPOND_TO_TIMESLOT_EVENT';
export const UPDATE_RESPONSE_TO_TIMESLOT_EVENT = 'calendar/UPDATE_RESPONSE_TO_TIMESLOT_EVENT';
export const BLOCK_TIMESLOT = 'calendar/BLOCK_TIMESLOT';
export const REMOVE_BLOCKING_OR_RESPONSE = 'calendar/REMOVE_BLOCKING_OR_RESPONSE';
export const LOAD_EVENTS_BY_GROUPID = 'calendar/LOAD_EVENTS_BY_GROUPID';
export const LOAD_EVENT_TYPES = 'calendar/LOAD_EVENT_TYPES';
export const EDIT_TIME_BY_DRAGGING_EVENT = 'calendar/EDIT_TIME_BY_DRAGGING_EVENT';
export const CLEAR_EVENTS = 'calendar/CLEAR_EVENTS';
export const UPDATE_DELEGATED_ACCESS = 'calendar/UPDATE_DELEGATED_ACCESS';
export const SET_EVENT_SELECTED_FROM_SEARCH = 'calendar/SET_EVENT_SELECTED_FROM_SEARCH';
export const LOAD_IMPORTANT_DATES = 'calendar/LOAD_IMPORTANT_DATES';
export const DELETE_EVENT = 'calendar/DELETE_EVENT';
export const MUTATE_UPDATE_MY_VACATION_REQUESTS_AFTER_REMOVING =
  'calendar/MUTATE_UPDATE_MY_VACATION_REQUESTS_AFTER_REMOVING';
export const LOAD_ASSIGNED_DELEGATED_ACCESSES = 'calendar/LOAD_ASSIGNED_DELEGATED_ACCESSES';
export const LOAD_MY_DELEGATED_ACCESSES = 'calendar/LOAD_MY_DELEGATED_ACCESSES';
export const DELETE_DELEGATED_ACCESSES = 'calendar/DELETE_DELEGATED_ACCESSES';
export const MODIFY_DATETIME_SELECTED_BY_CLICKED_TIMESLOT = 'calendar/MODIFY_DATETIME_SELECTED_BY_CLICKED_TIMESLOT';
export const RESPOND_EVENT = 'calendar/RESPOND_EVENT';
export const LOAD_BIRTHDAY_EVENTS = 'calendar/LOAD_BIRTHDAY_EVENTS';
export const LOAD_BIRTHDAY_EVENTS_FOR_GROUP = 'calendar/LOAD_BIRTHDAY_EVENTS_FOR_GROUP';
export const LIST_AVAILABLE_RESOURCES = 'calendar/LIST_AVAILABLE_RESOURCES';
export const LIST_RESOURCES = 'calendar/LIST_RESOURCES';
export const UPDATE_EVENT_RESOURCES = 'calendar/UPDATE_EVENT_RESOURCES';
export const REQUEST_VACATION = 'calendar/REQUEST_VACATION';
export const UPDATE_REQUEST_VACATION = 'calendar/UPDATE_REQUEST_VACATION';
export const ADD_VACATION = 'calendar/ADD_VACATION';
export const LOAD_DELEGATED_CONTEXT = 'calendar/LOAD_DELEGATED_CONTEXT';
export const SET_DELEGATED_CONTEXT = 'calendar/SET_DELEGATED_CONTEXT';
export const LOAD_MY_VACATION_REQUESTS = 'calendar/LOAD_MY_VACATION_REQUESTS';
export const LOAD_NOTICE_BOARD_EVENTS = 'calendar/LOAD_NOTICE_BOARD_EVENTS';
export const LOAD_NOTICE_BOARD_EVENT = 'calendar/LOAD_NOTICE_BOARD_EVENT';
export const UPDATE_CALENDAR_SYNC_CONSENT = 'calendar/UPDATE_CALENDAR_SYNC_CONSENT';
export const LOAD_CALENDAR_SYNC_CONSENT = 'calendar/LOAD_CALENDAR_SYNC_CONSENT';
export const LOAD_CALENDAR_FEEDS = 'calendar/LOAD_CALENDAR_FEEDS';
export const UPDATE_CALENDAR_FEED = 'calendar/UPDATE_CALENDAR_FEED';
export const CREATE_CALENDAR_FEED = 'calendar/CREATE_CALENDAR_FEED';
export const DELETE_CALENDAR_FEED = 'calendar/DELETE_CALENDAR_FEED';
export const LOAD_VACATION_REQUEST_RESPONSES = 'calendar/LOAD_VACATION_REQUEST_RESPONSES';
export const LOAD_GUARDIAN_WITH_PENDING_VACATION_REQUEST_RESPONSES =
  'calendar/LOAD_GUARDIAN_WITH_PENDING_VACATION_REQUEST_RESPONSES';
export const LOAD_MUNICIPAL_CALENDER_FEED = 'calendar/LOAD_MUNICIPAL_CALENDER_FEED';
export const UPDATE_MUNICIPAL_CALENDER_FEED = 'calendar/UPDATE_MUNICIPAL_CALENDER_FEED';
export const LOAD_CALENDAR_FEED_EVENTS = 'calendar/LOAD_CALENDAR_FEED_EVENTS';
export const RESET_ACTIVE_EVENT = 'calendar/RESET_ACTIVE_EVENT';
